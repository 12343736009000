// import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const WorkTile = ({ data }) => (
  <figure className="category-concept row">
    <div className="gallery-grid__image-wrap col-md-4">
      <img
        className="gallery-grid__image cover lazyload"
        src={data.image}
        data-zoom
        alt=""
      />
    </div>
    <figcaption
      className="gallery-grid__caption col-md-8"
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
      }}
    >
      <h4 className="title gallery-grid__title">{data.title}</h4>
      <span className="gallery-grid__category">{data.subtitle}</span>
      <p style={{margin: 0}}>{data.description}</p>
      <Link to={`/portfolio/${data.to}`} style={{textDecoration: "none", color: "#0081ff"}}>
        Read More
      </Link>
      <div>

      </div>
    </figcaption>
  </figure>
)

export default WorkTile
