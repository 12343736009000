import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout/layout.js"
// import Image from "../components/image"
// import SEO from "../components/seo"
import WorkTile from "../components/shared/work-tile"


const data = [
  {
    title: "Life Pharmacy Mobile App",
    subtitle: "UX/UI & Front-end",
    description: "",
    image: "../assets/img/image_01_small.jpg",
    to: "life-pharmacy-mobile-app",
  },
  {
    title: "KLU PWA",
    subtitle: "UX/UI & Front-end",
    description: "",
    image: "../assets/img/image_02_small.jpg",
    to: "klu-pwa",
  },

  {
    title: "Web Designs",
    subtitle: "Collections of website designs made for Clients.",
    description: "",
    image: "../assets/img/image_03_small.jpg",
    to: "web-designs",
  },

  {
    title: "Book Covers",
    subtitle: "Covers I designed for some books.",
    description: "",
    image: "../assets/img/image_04_small.jpg",
    to: "book-covers",
  },

  {
    title: "Some Random Stuff",
    subtitle: "Some Illustrations and Digital Drawings",
    description: "",
    image: "../assets/img/image_05_small.jpg",
    to: "random-stuff",
  },
  // {
  //   title: "2nd Work",
  //   subtitle: "asds",
  //   description: "description",
  //   image: "../assets/img/image_01.jpg",
  //   to: "work2",
  // },
]

const IndexPage = () => (
  <Layout>
    {/* <SEO title="Home" /> */}


    {/*<div className="pb-0 pb-sm-2">*/}
    {/*  <h1 className="title title--h1 title__separate">*/}
    {/*    About Me*/}
    {/*  </h1>*/}
    {/*  <p>*/}
    {/*    I'm Creative Director and UI/UX Designer from Sydney,*/}
    {/*    Australia, working in web development and print media. I*/}
    {/*    enjoy turning complex problems into simple, beautiful and*/}
    {/*    intuitive designs.*/}
    {/*  </p>*/}
    {/*  <p>*/}
    {/*    My job is to build your website so that it is functional*/}
    {/*    and user-friendly but at the same time attractive.*/}
    {/*    Moreover, I add personal touch to your product and make*/}
    {/*    sure that is eye-catching and easy to use. My aim is to*/}
    {/*    bring across your message and identity in the most*/}
    {/*    creative way. I created web design for many famous brand*/}
    {/*    companies.*/}
    {/*  </p>*/}
    {/*</div>*/}

    {/*<div className="gallery-grid js-masonry js-filter-container">*/}
    <div className="col-md-12">



      {data.map((item)=> {
        return <WorkTile data={item} key={item.title} />
      })}




      <br/>


    </div>
    {/* <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
